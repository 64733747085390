import SignBoard from "assets/images/sign-board 1.png"
import Subtract from "assets/images/Subtract.svg"
import { useParams } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import {
  Button,
  CheckboxInput,
  H5,
  H7,
  Input,
  Label,
  NotificationBar,
  P1,
  PasswordInput,
  PhoneInput,
  SelectInput,
  SelectOption,
  TextButton,
  TextLink,
} from "components"
import { useAppNotificationStore } from "stores"
import { Loader, LoadingIndicator } from "components/custom/Loader"
import { parseQuery } from "utils/parseQuery"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { isPadeDomain } from "utils/utils"

const OnboardingEmployer = () => {
  const [formSubmitted, toggleFormSubmitted] = useState(false)
  const [hasCode, toggleHasCode] = useState(false)
  const [canSetPassword, togglecanSetPassword] = useState(true)

  const navigate = useNavigate()
  const { state, search } = useLocation()

  const { toast } = useAppNotificationStore()
  const urlQuery = parseQuery(search)

  const { customizationSettings } = useCustomizationSettingsStore()

  const { mutateAsync: createAccount } = useMutation({
    mutationFn: async (data: IForm1) => {
      const res = await axiosInstance.post("/account/create", data)
      return res.data
    },
  })

  const { data: regCodeResp, isLoading: isGettingRegistrationCode } = useQuery({
    queryKey: ["reg-code", urlQuery.code],
    queryFn: async () => {
      toggleHasCode(true)
      const res = await axiosInstance.get(
        `/account/view/register?code=${parseQuery(search)}`
      )
      return res.data
    },
    enabled: urlQuery.code !== undefined,
    onSuccess: (res: any) => {
      if (res?.isPasswordSet) {
        // navigate("/auth/login");
      }

      form1.setValues({
        company_name: res?.companyName!,
        employees: res?.employees!,
        country_id: res?.countryId!,
        first_name: res?.firstName!,
        last_name: res?.lastName!,
        phone: res?.phone!,
        email: res?.email!,
        password: "",
        confirmPassword: "",
        registration_code: res?.code,
        agreed: false,
        is_employee: res.is_employee,
      })
    },
  })

  useEffect(() => {
    if (urlQuery.p !== undefined) {
      togglecanSetPassword(false)
    } else {
      togglecanSetPassword(true)
    }
  }, [urlQuery.p])

  const form1 = useFormik({
    initialValues: {
      company_name: "",
      employees: "",
      country_id: "162",
      first_name: "",
      last_name: "",
      phone: "",
      email: state?.email! ?? "",
      password: "",
      confirmPassword: "",
      registration_code: "",
      agreed: false,
      is_employee: "yes",
    },
    initialErrors: {},
    /* onIsEmployeeClicked: () => {

    },*/
    onSubmit: (values, { setTouched, resetForm, setSubmitting }) => {
      setTouched({})

      const {
        first_name,
        last_name,
        company_name,
        email,
        phone,
        country_id,
        employees,
        registration_code,
        password,
        is_employee,
      } = values

      if (hasCode === true) {
        setSubmitting(true)
        createAccount(
          {
            first_name,
            last_name,
            company_name,
            email,
            phone,
            country_id: country_id.toString(),
            registration_code,
            password,
            employees: employees.toString(),
            is_employee: "yes",
          },
          {
            onSuccess: (res) => {
              setSubmitting(false)
              resetForm()
              toggleFormSubmitted(true)
            },
          }
        )
      } else {
        setSubmitting(true)
        createAccount(
          {
            first_name,
            last_name,
            company_name,
            email,
            phone,
            country_id: country_id.toString(),
            employees: employees.toString(),
            is_employee: "yes",
            password: "",
            registration_code: "",
          },
          {
            onSuccess: (res: any) => {
              setSubmitting(false)
              if (res.status === "success") {
                resetForm()
                toggleFormSubmitted(true)
              } else {
                toast.error(res.title)
              }
            },
          }
        )
      }
    },
  })

  const brandPrimaryColor = customizationSettings?.brand?.colorScheme

  return (
    <div className="h-screen w-full">
      <NotificationBar />
      <div className="flex md:flex-row bg-primary50">
        <div className="hidden lg:block fixed top-0 bottom-0 left-0">
          <div
            className={`relative w-[417px] h-full md:pt-[157px] md:px-12 bg-primary500`}
          >
            <img
              className="absolute h-[119px] bottom-[66px] right-0"
              src={SignBoard}
              alt=""
            />
            <img
              className="absolute w-[70px] bottom-[76px] right-0"
              src={Subtract}
              alt=""
            />
            <h1 className="text-[#F5F6F7] circularstdbold font-bold text-[32px] leading-[40.48px] mb-[38px] md:mr-4">
              {customizationSettings?.pages?.businessRegistration?.title}
            </h1>
            <p className="text-[#DFE2E6] avenirstdroman font-normal text-[18px] leading-[25px]">
              {customizationSettings?.pages?.businessRegistration?.description}
            </p>
          </div>
        </div>
        <div className="lg:ml-[417px] lg:w-[calc(100%-417px)] min-h-screen h-full grow pt-[41px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px]">
          {formSubmitted === false ? (
            <div>
              <div className="flex justify-between gap-2">
                <TextButton
                  title="Back"
                  className="flex gap-2 text-neutral500 font-medium items-center"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeftIcon className="w-4 text-primary500" />
                  <span>Back</span>
                </TextButton>
                {isPadeDomain() ? (
                  <TextButton
                    title="Get help"
                    className="flex gap-2 items-center"
                    onClick={() =>
                      (window.location.href = "https://support.padehcm.com")
                    }
                  >
                    <span>Having troubles?</span>
                    <span className="text-primary500 underline">Get help</span>
                    <ArrowRightIcon className="w-4 text-primary500" />
                  </TextButton>
                ) : null}
              </div>
              <h2 className="mt-[50px] md:mt-[70px] circularstdbold font-bold text-[32px] leading-[40px] text-[#243757] mb-[16px]">
                Create your {customizationSettings?.brand?.platformName} account
              </h2>
              <h3 className="max-w-[533px] avenirstdbook text-[16px] leading-[21px] text-neutral100 mb-[48px]">
                Fill in the form below
              </h3>

              <div className="max-w-full lg:max-w-[541px] text-neutral200">
                <Loader
                  message="Verifying registration code..."
                  loading={isGettingRegistrationCode ? "true" : "false"}
                >
                  <form
                    autoComplete="off"
                    onSubmit={form1.handleSubmit}
                    className="w-full"
                  >
                    <div className="flex flex-col gap-9">
                      <div className="flex flex-col gap-2">
                        <Label
                          required="true"
                          htmlFor="country_id"
                        >
                          Country
                        </Label>
                        <SelectInput
                          id="country_id"
                          name="country_id"
                          disabled={hasCode || form1.isSubmitting}
                          required={true}
                          placeholder="Please select country"
                          onChange={form1.handleChange}
                          value={form1.values.country_id}
                          className="border-primary50"
                        >
                          <SelectOption value={162}>Nigeria</SelectOption>
                        </SelectInput>
                      </div>
                      <div className="flex flex-col md:flex-row md:justify-between gap-5">
                        <div className="flex flex-col gap-2 w-full">
                          <Label
                            required="true"
                            htmlFor="first_name"
                          >
                            First name
                          </Label>
                          <Input
                            id="first_name"
                            name="first_name"
                            required={true}
                            pattern="[\w]{1,}"
                            // readOnly={hasCode}
                            disabled={form1.isSubmitting}
                            placeholder="First name"
                            onChange={form1.handleChange}
                            value={form1.values.first_name}
                            className="border-primary50"
                          />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <Label
                            required="true"
                            htmlFor="last_name"
                          >
                            Last name
                          </Label>
                          <Input
                            id="last_name"
                            name="last_name"
                            required={true}
                            pattern="[\w]{1,}"
                            // readOnly={hasCode}
                            disabled={form1.isSubmitting}
                            placeholder="Last name"
                            onChange={form1.handleChange}
                            value={form1.values.last_name}
                            className="border-primary50"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <Label
                          required="true"
                          htmlFor="company_name"
                        >
                          Company name
                        </Label>
                        <Input
                          id="company_name"
                          name="company_name"
                          required={true}
                          pattern="[\w ]{1,}"
                          // readOnly={hasCode}
                          disabled={form1.isSubmitting}
                          placeholder="Company name"
                          onChange={form1.handleChange}
                          value={form1.values.company_name}
                          className="border-primary50"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row md:justify-between gap-5">
                        <div className="flex flex-col gap-2 md:w-3/5 w-full">
                          <Label
                            required="true"
                            htmlFor="employees"
                          >
                            Number of employees
                          </Label>
                          <Input
                            type={"number"}
                            id="employees"
                            name="employees"
                            required={true}
                            min={1}
                            //  readOnly={hasCode}
                            disabled={form1.isSubmitting}
                            placeholder="Select number of employees"
                            onChange={form1.handleChange}
                            value={form1.values.employees}
                            className="border-primary50"
                          />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <Label
                            required="true"
                            htmlFor="phone"
                          >
                            Phone number
                          </Label>
                          <PhoneInput
                            id="phone"
                            name="phone"
                            required={true}
                            pattern="[\d]{10,}$"
                            // readOnly={hasCode}
                            disabled={form1.isSubmitting}
                            placeholder="Enter phone number"
                            onChange={form1.handleChange}
                            setValue={form1.setFieldValue}
                            value={form1.values.phone}
                            className="border-primary50"
                            maxLength={15}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label
                          required="true"
                          htmlFor="email"
                        >
                          Email address
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          required={true}
                          // readOnly={hasCode}
                          disabled={form1.isSubmitting}
                          placeholder="Enter email address"
                          onChange={form1.handleChange}
                          value={form1.values.email}
                          className="border-primary50"
                        />
                      </div>
                      {/*
                      <div className="w-full flex gap-2 items-start">
                        <CheckboxInput
                          id="isEmp"
                          name="isEmp"
                          required={true}
                          className="h-4 w-4"
                          onChange={form1.handleChange}
                          checked={
                            form1.values.is_employee === "yes" ? true : false
                          }
                        />
                        <Label htmlFor="agreed">
                          Will you be part of employees included in payroll?
                        </Label>
                      </div> */}
                      {hasCode && (
                        <>
                          <div className="flex flex-col gap-2">
                            <Label
                              required="true"
                              htmlFor="password"
                            >
                              Password
                            </Label>
                            <PasswordInput
                              id="password"
                              name="password"
                              required={true}
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              disabled={form1.isSubmitting}
                              placeholder="Enter password"
                              onChange={form1.handleChange}
                              value={form1.values.password}
                              className="border-primary50"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <Label
                              required="true"
                              htmlFor="confirmPassword"
                            >
                              Confirm password
                            </Label>
                            <PasswordInput
                              id="confirmPassword"
                              name="confirmPassword"
                              required={true}
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              disabled={form1.isSubmitting}
                              placeholder="Enter password"
                              onChange={form1.handleChange}
                              value={form1.values.confirmPassword}
                              className="border-primary50"
                            />
                          </div>
                        </>
                      )}
                      <div className="w-full flex gap-2 items-start">
                        <CheckboxInput
                          id="agreed"
                          name="agreed"
                          required={true}
                          className="h-4 w-4"
                          onChange={form1.handleChange}
                          checked={form1.values.agreed}
                        />
                        <Label htmlFor="agreed">
                          Signing up signifies that you have read and agree to
                          the{" "}
                          <a
                            className="text-primary500"
                            href={`${process.env.REACT_APP_WEBSITE_URL}/terms-and-conditions`}
                          >
                            Terms of Service
                          </a>{" "}
                          and our{" "}
                          <a
                            className="text-primary500"
                            href={`${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`}
                          >
                            Privacy Policy
                          </a>
                          .
                        </Label>
                      </div>
                      <Button
                        type="submit"
                        color="primary"
                        title="Create my account"
                        // disabled={!form1.isValid || form1.isSubmitting}
                        className="font-bold"
                      >
                        {form1.isSubmitting && <LoadingIndicator />}
                        Create my account
                      </Button>
                    </div>
                  </form>
                </Loader>
              </div>
            </div>
          ) : (
            <div className="h-full max-w-[437px] flex flex-col justify-center items-center gap-y-[34px] md:pt-[100px]">
              {hasCode === true ? (
                <>
                  <CheckCircleIcon className="text-success500 w-52" />
                  <div className="w-full flex flex-col gap-4 items-center">
                    <H5 className="text-neutral600 font-bold">
                      Registration Completed!
                    </H5>

                    <P1 className="text-center text-neutral100">
                      Thank you for signing up with us! We're thrilled to have
                      you as part of our community. Your new account has been
                      successfully created, and we look forward to providing you
                      with a great experience. Please proceed to sign in
                      <Button
                        type="submit"
                        title="Get started"
                        className="h-full font-bold mt-10"
                        color="primary"
                        onClick={() => {
                          navigate("/auth/login")
                        }}
                      >
                        <span>Proceed to Login</span>
                        <ArrowRightIcon className="w-5" />
                      </Button>
                    </P1>
                  </div>
                </>
              ) : (
                <>
                  <H7 className="text-neutral500">Almost there!</H7>
                  <CheckCircleIcon className="text-success500 w-52" />
                  <div className="w-full flex flex-col gap-4 items-center">
                    <H5 className="text-neutral600 font-bold">
                      Verify your Email
                    </H5>
                    <P1 className="text-center text-neutral100">
                      We have sent a confirmation email to {state.email}. Please
                      click the link that was sent to complete signup.If you
                      cant find the mail in your inbox, kindly check your
                      junk/spam folder just in case and add us to your safe
                      senders list. Please proceed to{" "}
                      <TextLink
                        title="Sign in"
                        className="text-primary500 no-underline"
                        to="/auth/login"
                      >
                        sign in
                      </TextLink>{" "}
                      if you havent received the email in 5minutes.
                    </P1>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OnboardingEmployer

interface IForm1 {
  first_name: string
  last_name: string
  company_name: string
  email: any
  phone: string
  country_id: string
  registration_code: string
  password: string
  employees: string
  is_employee: string
}
