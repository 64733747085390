import { Button, Label } from "components"
import React, { useEffect, useState } from "react"
import { Select, Checkbox, Form, Spin, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import AntToggle from "components/inputs/AntToggle"
import { IPreviewData } from "../SendMoneyBank"
import EnterPin from "../pin/EnterPin"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { ITransferWalletBody } from "./interface"
import {
  IBeneficiaries,
  IEmployeeForWallet,
  IResolveWallet,
} from "../beneficiaries/interface"
import { getAcronym } from "../Wallet"
interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<IPreviewData[]>>
  amountOverBalance: boolean
  allBeneficiariesFilled: boolean
  x?: string | null
}

const SingleWallet = ({
  setPreviewData,
  amountOverBalance,
  allBeneficiariesFilled,
  x,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const { toast } = useAppNotificationStore()
  const [sendToEmployee, setSendToEmployee] = useState(false)
  const [saveBeneficiary, setSaveBeneficiary] = useState(false)
  const [showPinModal, setShowPinModal] = useState(false)
  const [formBodySingle, setFormBodySingle] = useState<any>({
    accountNumber: "",
    accountName: "",
    bankName: "",
  })
  const amount = Form.useWatch("amount", form)
  const walletID = Form.useWatch("wallet_id", form)
  const description = Form.useWatch("description", form)

  const { isLoading: isLoadingEmployees, data: employeesData } =
    useQuery<IEmployeeForWallet>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/user-group-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/user-group-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IBeneficiaries) => {
        if (x) {
          const benFound = data?.data?.find((ben) => ben._id === x)
          if (benFound) {
            form.setFieldValue("wallet_id", benFound?.accountNumber)
            setFormBodySingle({
              accountNumber: benFound?.accountNumber,
              accountName: benFound?.accountName,
              bankName: benFound?.bankName,
            })
          }
        }
      },
    })

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITransferWalletBody) => {
      const res = await axiosInstance.post(
        `employee/wallet/initiate_wallet_transfer`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        navigate(
          user?.userStatusId === "2" || user?.userStatusId === "3"
            ? "/employee/susp-exit/wallet/overview"
            : "/employee/dashboard/wallet/overview"
        )
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const { isLoading: getBankDetails, data: bankDetails } =
    useQuery<IResolveWallet>({
      queryKey: [`${walletBaseUrl}/v1/wallets/resolve-wallet`, walletID],
      queryFn: async () => {
        const res = await axiosInstance.post(
          `${walletBaseUrl}/v1/wallets/resolve-wallet`,
          {
            accountNumber: walletID,
          },

          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },
      retry: 1,
      enabled: !sendToEmployee && walletID?.toString().length === 9,
      // refetchOnWindowFocus: false,
      // cacheTime: 24 * 60 * 60 * 1000,

      onSuccess: (data) => {
        setFormBodySingle({
          accountNumber: data?.data?.accountNumber,
          accountName: data?.data?.accountName,
          bankName: data?.data?.bankName,
        })
      },
      onError: (data: any) => {
        toast.error(data?.response?.data?.message)
        setFormBodySingle({
          ...formBodySingle,
          accountName: "",
        })
      },
    })
  useEffect(() => {
    setPreviewData([
      {
        beneficiary: formBodySingle?.accountName,
        bank_name: formBodySingle?.bankName || "PaidHR Wallet",
        account_number: walletID || formBodySingle?.accountNumber,
        amount: amount ?? "0",
      },
    ])
  }, [amount, walletID, formBodySingle, sendToEmployee])
  const sampleAmounts = [5000, 10000, 20000, 50000]
  const handleSubmit = (value: string) => {
    submit({
      // walletId: EmployeeWalletID ? EmployeeWalletID : "",
      // transactionPin: value,
      pin: value,
      payments: [
        {
          walletId: formBodySingle?.accountNumber,
          amount: Number(amount) ?? 0,
          currency: "NGN",
          description: description,
          addToBeneficiary: saveBeneficiary,
        },
      ],
    })
  }
  const onFinish = (values: any) => {
    setShowPinModal(true)
  }
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px]">
            <div className=" mt-5 flex flex-col items-start w-full">
              <div className="w-full">
                <CustomInput
                  label="Payment description"
                  name="description"
                  required
                />{" "}
              </div>
              <div className="mb-6">
                <Label className="mb-3">Select amount </Label>
                <div className="flex  flex-wrap items-center gap-2">
                  {sampleAmounts.map((amount, index) => (
                    <button
                      type="button"
                      key={index}
                      className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                      onClick={(e) => {
                        e.preventDefault()
                        form.setFieldValue("amount", String(amount))
                      }}
                    >
                      ₦ {formatCurrencyNoSymbol(amount)}
                    </button>
                  ))}
                </div>
              </div>
              <div className="w-full  ">
                <Form.Item
                  name="amount"
                  label={
                    <span className="text-neutral300 text-sm ">
                      Enter other amount
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input amount",
                    },
                  ]}
                >
                  <InputNumber<number>
                    addonBefore="₦"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value?.replace(/,/g, "") as unknown as number
                    }
                    className="w-full"
                    placeholder="e.g 200"
                  />
                </Form.Item>
              </div>
              <div className=" flex  items-center gap-10 mb-6">
                <Label>
                  Select beneficiary from your organization
                  <div className="relative mt-1 h-6">
                    {employeesData?.data?.docs
                      ?.slice(0, 5)
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                          style={{ left: `${index * 20}px` }}
                        >
                          {getAcronym(item?.commonName)}{" "}
                        </div>
                      ))}
                  </div>
                </Label>

                <AntToggle
                  name="usebeneficiary"
                  label=""
                  checked={sendToEmployee}
                  onChange={(e: any) => {
                    form.setFieldValue("wallet_id", "")
                    form.setFieldValue("beneficiary", "")
                    setFormBodySingle({
                      accountNumber: "",
                      bankName: "",
                      accountName: "",
                    })
                    setSendToEmployee(!sendToEmployee)
                  }}
                />
              </div>
              {/* USE BENEFICIARY OPTION */}
              {sendToEmployee ? (
                <div className="w-full">
                  <div className="mt-1">
                    <Form.Item
                      label={
                        <span className="font-circular text-neutral300 text-sm  ">
                          Beneficiary name
                        </span>
                      }
                      name="beneficiary"
                      rules={[
                        { required: true, message: "Please enter this field!" },
                      ]}
                    >
                      <Select
                        showSearch
                        loading={isLoadingEmployees}
                        onChange={(value) => {
                          const selected = employeesData?.data?.docs?.find(
                            (item) => item.walletId === value
                          )
                          if (selected) {
                            setFormBodySingle({
                              accountNumber: selected?.walletId,
                              // bankName: data?.data?.bankName,
                              accountName: selected?.commonName,
                            })
                          }
                        }}
                        options={employeesData?.data?.docs?.map((item) => ({
                          label: item.commonName,
                          value: item.walletId,
                        }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Type employee name"
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <CustomInput
                    label="PaidHR Wallet ID"
                    name="wallet_id"
                    required
                    type="number"
                    placeholder="Enter 9-digit Wallet Id"
                    disabled={
                      getBankDetails &&
                      !sendToEmployee &&
                      walletID?.toString().length === 9
                    }
                    extra={
                      walletID &&
                      walletID?.toString().length === 9 &&
                      getBankDetails ? (
                        <div>
                          <Spin className="h-4 w-4" />
                        </div>
                      ) : formBodySingle?.accountName ? (
                        <span>{formBodySingle?.accountName}</span>
                      ) : null
                    }
                  />
                  <div className=" flex  items-center gap-2">
                    <Label>Save beneficiary</Label>
                    <AntToggle
                      name="usebeneficiary"
                      label=""
                      checked={saveBeneficiary}
                      onChange={(e: any) => {
                        setSaveBeneficiary(!saveBeneficiary)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center gap-3 mt-2">
              <Form.Item
                name="scheduleConfirmation"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "You must confirm the payment details above.",
                  },
                ]}
              >
                <Checkbox className="text-sm text-[#5D6B82]">
                  I confirm the payment details above
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link
              to={
                user?.userStatusId === "2" || user?.userStatusId === "3"
                  ? "/employee/susp-exit/wallet/overview"
                  : "/employee/dashboard/wallet/overview"
              }
            >
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              disabled={amountOverBalance || !allBeneficiariesFilled}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
      <EnterPin
        title="Enter  transaction Pin"
        onClose={() => setShowPinModal(false)}
        open={showPinModal}
        loading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default SingleWallet
