import React, { useState } from "react"
import { Form, FormItemProps, Select, SelectProps } from "antd"

type Props = {
  isLoading?: boolean
  options: any[] | undefined
  handleOnChange: Function
}

export const AntDesignSelect = ({
  label,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options = [],
  onChange,
  onSelectClick,
  isLoading = false,
  onSelect,
  onDeSelect,
  // mode = "multiple",
  mode,
  value,
  tooltip,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      className="w-full"
      tooltip={tooltip && tooltip}
      label={
        label ? (
          <span className="font-circular text-neutral300 text-sm ">
            {label}
          </span>
        ) : (
          ""
        )
      }
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Select
        {...restProps}
        loading={isLoading}
        className="w-full"
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        value={value || undefined}
        onClick={onSelectClick}
        // filterOption={(input, option) => {
        //   if (option) {
        //     const value = (option.value as string) || ""
        //     return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        //   } else {
        //     return false
        //   }
        // }}
        filterOption={(input, option) =>
          (option?.children ?? "")
            // @ts-ignore
            ?.toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        mode={mode}
        options={isLoading ? [] : options}
        onSelect={onSelect}
        onDeselect={onDeSelect}
      />
    </Form.Item>
  )
}

export default AntDesignSelect
type SelectItemProps = FormItemProps &
  SelectProps & {
    label: string
    required?: boolean
    showSearch?: boolean
    tooltip?: string
    onChange?: (e: any) => void
    onSelectClick?: VoidFunction
    placeholder: string
    options: { label: string; value: string }[] | any[] | undefined
    isLoading?: boolean
    mode?: string // New property for multiple selections
    value?: string[] | string
    onSelect?: (value: any, option: any) => void
    onDeSelect?: (value: any, option: any) => void
  }
