import { Button } from "components"
import React, { useEffect, useMemo, useState } from "react"
import { Select, Checkbox, Form, Switch, Spin, Input, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import { IPreviewData } from "../SendMoneyBank"
import { useMutation, useQuery } from "@tanstack/react-query"
import { IBeneficiaries } from "../beneficiaries/interface"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import DeleteIcon from "../../../../../assets/images/delete_icon.svg"
import { getAcronym } from "../Wallet"
import EnterPin from "../pin/EnterPin"
import { IFormBody, ITransferBankBody } from "./interface"
import { groupFormDataByIndex } from "./component"
import { KycLevel } from "../kyc/interface"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<IPreviewData[]>>
  currentLevel: null | KycLevel
  amountOverBalance: boolean
  fee: string
  setFee: React.Dispatch<React.SetStateAction<string>>
  submitFee: (body: any) => void
  setTotal: (body: any) => void
  allBeneficiariesFilled: boolean
}

const MultipleBank = ({
  setPreviewData,
  currentLevel,
  amountOverBalance,
  fee,
  setFee,
  submitFee,
  setTotal,
  allBeneficiariesFilled,
}: Props) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [showPinModal, setShowPinModal] = useState(false)
  const dataConfirmed = Form.useWatch("scheduleConfirmation", form)
  const [formBodyMultiple, setFormBodyMultiple] = useState<IFormBody[]>([
    {
      accountNumber: "",
      bankCode: "",
      bankName: "",
      amount: "",
      currency: "",
      description: "",
      sendToBeneficiary: false,
      bankDetails: null,
      addToBeneficiary: false,
    },
  ])
  const { isLoading: getBanksLoading, data: banksData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/banks`],
    queryFn: async () => {
      const res = await axiosInstance.get(`${walletBaseUrl}/v1/wallets/banks`, {
        headers: {
          api_key: walletPublicKey,
        },
      })
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,

    onSuccess: (data: any) => {},
  })
  const { isLoading: getBankDetails, mutate: submitGetBankDet } = useMutation({
    mutationFn: async (value: { body: any; index: number }) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/resolve-account`,
        value.body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      if (res) {
        form.setFieldValue(
          `accountName~${value.index}`,
          String(res?.data?.data?.accountName)
        )
        setFormBodyMultiple((prev) => {
          return prev.map((item, i) => {
            if (i === value?.index) {
              return {
                ...item,
                bankDetails: res?.data?.data?.accountName,
              }
            }
            return item
          })
        })
      }

      return res
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })

  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITransferBankBody) => {
      const res = await axiosInstance.post(
        `employee/wallet/initiate_bank_transfer`,
        body
      )

      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        navigate(
          user?.userStatusId === "2" || user?.userStatusId === "3"
            ? "/employee/susp-exit/wallet/overview"
            : "/employee/dashboard/wallet/overview"
        )
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })

  const sampleAmounts = [5000, 10000, 20000, 50000]

  const handleInputChange = async (
    index: number,
    field: keyof IFormBody | string,
    value: string | boolean
  ) => {
    const updatedFormBodies = [...formBodyMultiple]
    updatedFormBodies[index] = {
      ...updatedFormBodies[index],
      [field]: value,
    }

    // Set the updated state
    setFormBodyMultiple(updatedFormBodies)

    const initialValues = form.getFieldsValue()
    const bankHasBeenSelected = initialValues[`bank~${index}`]

    if (
      field === `accountNumber~${index}` &&
      typeof value === "string" &&
      value.length === 10 &&
      bankHasBeenSelected
    ) {
      const ResolveBody = {
        body: {
          accountNumber: value,
          bankCode: bankHasBeenSelected,
          currency: "NGN",
        },
        index,
      }

      try {
        const response: any = await submitGetBankDet(ResolveBody) // Await the response here
        updatedFormBodies[index].bankDetails = response // Assuming response contains the bank details
        setFormBodyMultiple(updatedFormBodies)
      } catch (error) {
        console.error("Error fetching bank details:", error)
        // Handle errors as needed
      }
    }

    // Optionally update form fields value (if using Ant Design Form)
    form.setFieldsValue({ formBodyMultiple })
  }

  // Handler to add a new form body
  const handleAddRecipient = () => {
    setFormBodyMultiple([
      ...formBodyMultiple,
      {
        accountNumber: "",
        bankCode: "",
        bankName: "",
        amount: "",
        currency: "",
        description: "",
        addToBeneficiary: false,
        sendToBeneficiary: false,
        bankDetails: null,
      },
    ])
  }

  // Handler to delete a form body
  const handleDeleteRecipient = (index: number) => {
    const updatedFormBodies = formBodyMultiple.filter((_, i) => i !== index)
    setFormBodyMultiple(updatedFormBodies)
  }

  const onFinish = (values: any) => {
    // cancelIdleCallback
    setShowPinModal(true)
  }
  const handleSubmit = (value: string) => {
    const formData = form.getFieldsValue()
    const groupedData = groupFormDataByIndex(formData)
    submit({
      // walletId: EmployeeWalletID ? EmployeeWalletID : "",
      // transactionPin: value,
      pin: value,
      payments:
        groupedData.length > 0
          ? groupedData.map((data) => ({
              accountNumber: data.accountNumber ?? "",
              bankCode: data.bank ?? "",
              amount: Number(data?.amount) ?? 0,
              currency: "NGN",
              description: data.description ?? "",
              addToBeneficiary: data?.addBeneficiary ? true : false,
            }))
          : [],
    })
  }

  useEffect(() => {
    const formattedPreviewData = formBodyMultiple?.map((item, index) => ({
      beneficiary: item?.bankDetails,
      bank_name: item?.bankName,
      account_number: item?.accountNumber,
      amount: item?.amount ?? "0",
    }))

    setPreviewData(formattedPreviewData)
  }, [formBodyMultiple])

  const filteredAmounts = useMemo(() => {
    return formBodyMultiple
      .map((item) => ({
        amount: Number(item.amount),
        currency: "NGN",
      }))
      .filter((item) => item.amount > 0)
  }, [formBodyMultiple])

  useEffect(() => {
    if (dataConfirmed === true && filteredAmounts.length) {
      submitFee({
        walletId: EmployeeWalletID,
        payments: filteredAmounts,
      })
    }
  }, [dataConfirmed, filteredAmounts])

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ scheduleConfirmation: false }}
      >
        <>
          <div className="">
            <div className=" mt-5 flex flex-col items-start w-full ">
              <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                <CustomInput
                  label="Payment description"
                  name="description"
                  required
                />{" "}
              </div>
              {formBodyMultiple.map((item, index) => (
                <div
                  key={index}
                  className={` w-full ${
                    index !== formBodyMultiple?.length - 1
                      ? "mb-6 border-b border-[#C2C7D0]"
                      : ""
                  } px-4 md:px-6 lg:px-8 xl:px-[40px]`}
                >
                  <div className="flex items-center mb-4">
                    <p className="font-bold text-[15px] font-circular  text-padeBlack  ">
                      Recipient {index + 1}
                    </p>
                    {formBodyMultiple.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleDeleteRecipient(index)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          className="ml-6 "
                        />
                      </button>
                    )}
                  </div>
                  <div className="mb-6">
                    <span className="mb-3 text-neutral500 text-sm font-avenir">
                      Select amount{" "}
                    </span>
                    <div className="flex  flex-wrap items-center gap-2">
                      {sampleAmounts.map((amount, index) => (
                        <button
                          type="button"
                          key={index}
                          className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                          onClick={() => {
                            handleInputChange(
                              index,
                              `amount~${index}`,
                              String(amount)
                            )
                            form.setFieldValue(
                              `amount~${index}`,
                              String(amount)
                            )

                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    amount: String(amount),
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        >
                          ₦ {formatCurrencyNoSymbol(amount)}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="w-full mb-6">
                    <Form.Item
                      label={
                        <span className="text-neutral300 text-sm flex items-center">
                          Enter amount{" "}
                        </span>
                      }
                      name={`amount~${index}`}
                      required
                      rules={[
                        {
                          validator: (_, value) => {
                            const limit =
                              currentLevel && currentLevel.level > 2
                                ? Number.MAX_SAFE_INTEGER
                                : 200000
                            if (!value || value <= limit) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                `You can only send a maximum of ${formatCurrencyNoSymbol(
                                  limit
                                )} to other banks, kindly upgrade your account `
                              )
                            )
                          },
                        },
                        {
                          validator: (_, value) => {
                            const minimumAmount = 200
                            if (!value || value >= minimumAmount) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                `Amount must be at least ₦${minimumAmount}`
                              )
                            )
                          },
                        },
                      ]}
                    >
                      <InputNumber<number>
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) =>
                          value ? parseFloat(value.replace(/,/g, "")) : 0
                        }
                        className="font-circular text-neutral400 w-full"
                        addonBefore="₦"
                        placeholder=""
                        onChange={(e: any) => {
                          handleInputChange(index, `amount~${index}`, e)
                          setFormBodyMultiple((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  amount: e,
                                }
                              }
                              return item
                            })
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className=" flex  items-center gap-9 mb-6">
                    <span className="mb-3 text-neutral500 text-sm font-avenir">
                      Select from saved beneficiaries to send
                      <div className="relative mt-1 h-6">
                        {beneficiariesData?.data
                          ?.slice(0, 5)
                          ?.map((ben, index) => (
                            <div
                              key={index}
                              className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                              style={{ left: `${index * 20}px` }}
                            >
                              {getAcronym(ben?.accountName)}
                            </div>
                          ))}
                      </div>
                    </span>
                    <Form.Item
                      name={`usebeneficiary~${index}`}
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked: boolean) => {
                          setFormBodyMultiple((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  sendToBeneficiary: checked,
                                }
                              }
                              return item
                            })
                          })
                          form.setFieldValue(`usebeneficiary~${index}`, checked)
                        }}
                        className="bg-[#ededed]"
                      />
                    </Form.Item>
                  </div>

                  {/* USE BENEFICIARY OPTION */}
                  {item?.sendToBeneficiary ? (
                    <div className="w-full">
                      <div className="mt-1">
                        <Form.Item
                          label={
                            <span className="font-circular text-neutral300 text-sm  ">
                              Select beneficiary to send
                            </span>
                          }
                          name={`beneficiary~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            loading={isLoadingBeneficiaries}
                            onChange={(value) => {
                              const selected = beneficiariesData?.data?.find(
                                (item) => item.accountName === value
                              )

                              if (selected) {
                                form.setFieldValue(
                                  `bank~${index}`,
                                  String(selected?.bankCode)
                                )

                                form.setFieldValue(
                                  `accountNumber~${index}`,
                                  String(selected?.accountNumber)
                                )

                                setFormBodyMultiple((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        bankDetails: selected?.accountName,
                                        bankCode: String(selected?.bankCode),
                                        bankName: String(selected?.bankName),
                                        accountNumber: String(
                                          selected?.accountNumber
                                        ),
                                      }
                                    }
                                    return item
                                  })
                                })
                              }
                            }}
                            options={beneficiariesData?.data
                              ?.filter((item) => item.type === "bank")
                              ?.map((item) => ({
                                label: item?.accountName,
                                value: item?.accountName,
                              }))}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            placeholder="Select beneficiary"
                          />
                        </Form.Item>
                        <div className="opacity-0 h-1 overflow-hidden">
                          <Form.Item name={`bank~${index}`}></Form.Item>
                          <Form.Item
                            name={`accountNumber~${index}`}
                          ></Form.Item>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full">
                      <div>
                        <Form.Item
                          label={
                            <span className="font-circular text-neutral300 text-sm  ">
                              Bank
                            </span>
                          }
                          name={`bank~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            loading={getBanksLoading}
                            onChange={(value) => {
                              const selected = banksData?.data?.find(
                                (item: any) => item.code === value
                              )
                              if (selected) {
                                handleInputChange(index, `bank~${index}`, value)
                                setFormBodyMultiple((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        bankCode: String(selected?.code),
                                        bankName: String(selected?.name),
                                      }
                                    }
                                    return item
                                  })
                                })
                              }
                            }}
                            options={banksData?.data?.map((item: any) => ({
                              label: item.name,
                              value: item.code,
                            }))}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                      </div>
                      <CustomInput
                        name={`accountNumber~${index}`}
                        label="Account number"
                        required
                        onChange={(e) => {
                          handleInputChange(
                            index,
                            `accountNumber~${index}`,
                            e.target.value
                          )
                          setFormBodyMultiple((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  accountNumber: e.target.value,
                                }
                              }
                              return item
                            })
                          })
                        }}
                        extra={
                          !item?.bankDetails && getBankDetails ? (
                            <div>
                              <Spin className="h-4 w-4" />
                            </div>
                          ) : item?.bankDetails ? (
                            <span className="text-xs">{item?.bankDetails}</span>
                          ) : null
                        }
                      />

                      <div className=" flex  items-center gap-2 h-fit mb-5">
                        <span className=" text-neutral500 text-sm font-avenir">
                          Save beneficiary
                        </span>
                        <Form.Item
                          name={`addBeneficiary~${index}`}
                          valuePropName="checked"
                          style={{ marginBottom: 0 }}
                        >
                          <Switch
                            onChange={(checked: boolean) => {
                              setFormBodyMultiple((prev) => {
                                return prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      addToBeneficiary: checked,
                                    }
                                  }
                                  return item
                                })
                              })
                              form.setFieldValue(
                                `addBeneficiary~${index}`,
                                checked
                              )
                            }}
                            className="bg-[#ededed]"
                          />
                        </Form.Item>
                        {/* s */}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-4 w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
              {formBodyMultiple.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddRecipient}
                  className="text-primary500  font-circular text-sm underline"
                >
                  +Add Another Recipient
                </button>
              )}
              <div className="flex items-center gap-3 mt-2">
                <Form.Item
                  name="scheduleConfirmation"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              "You must confirm the payment details above."
                            ),
                    },
                  ]}
                >
                  <Checkbox className="text-sm text-[#5D6B82]">
                    I confirm the payment details above
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className=" flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              disabled={amountOverBalance || !allBeneficiariesFilled}
              onClick={() => form.submit()}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
      <EnterPin
        title="Enter Transaction Pin"
        onClose={() => setShowPinModal(false)}
        open={showPinModal}
        loading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default MultipleBank
