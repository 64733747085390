import SignBoard from "assets/images/sign-board 1.png"
import Subtract from "assets/images/Subtract.svg"
import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { Button, NotificationBar, TextButton } from "components"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import { Checkbox, Form, Input, Select, Space } from "antd"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { CustomInput } from "components/new-stuff/form"
import { useAppNotificationStore } from "stores"
import ReCAPTCHA from "react-google-recaptcha"
import { useState } from "react"

interface IForm1 {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  country: string
  employeeCount: number
  companyName: string
  products: string[]
}

const OnboardingBusinessRegisteration = () => {
  const navigate = useNavigate()
  const { customizationSettings } = useCustomizationSettingsStore()
  const { toast } = useAppNotificationStore()

  const {
    isLoading,
    mutate: createAccount,
    data,
  } = useMutation({
    mutationFn: async (body: IForm1) => {
      const baseurl =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_EWA_BASE_URL
          : process.env.REACT_APP_EWA_BASE_URL + "/"

      const res = await axios.post(`${baseurl}v1/accounts`, body)
      return res.data
    },
    onSuccess: (data) => {},
    onError(error: any) {
      if (error?.response?.data?.message) {
        const toastError = error.response.data.message.split(",")

        toastError?.forEach((item: string) => {
          toast.error(item)
        })
      }
    },
  })

  const isSubmitted = data?.success === true

  return (
    <>
      <NotificationBar />
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading={"A few clicks from creating your account"}
          subHeading={"Start in minutes, save time and money"}
          showGetHelp
          showCompanies={false}
          showBackBtn
          progressData={{ percent: 75, text: "3/4" }}
          yelloBgClassName="!min-h-[200vh] !right-[280px]"
        >
          {isSubmitted ? (
            <div className=" flex flex-col items-center justify-center min-h-full">
              <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
              <h2 className="font-avenir font-bold text-neutral700 text-2xl mt-4 mb-4 text-center">
                Email verification sent
              </h2>
              <p className="font-avenir font-semibold text-base text-neutral400 mb-6 text-center">
                A verification email has been sent to your inbox. Please verify
                your email to continue.
              </p>
            </div>
          ) : (
            <div className="">
              <NotificationBar />
              <h2 className="font-circular font-bold text-3xl mb-6">
                Create your {customizationSettings?.brand?.platformName} account
              </h2>
              <p className="font-avenir font-semibold text-base text-neutral400 mb-6">
                Fill in the forms below
              </p>
              <div className="">
                <div id="">
                  <FormBody
                    onSubmit={(values) => {
                      createAccount(values)
                    }}
                    submitting={isLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </NewAuthWrapper>
      ) : (
        <div className="h-screen w-full">
          <div className="flex md:flex-row bg-primary50">
            <div className="hidden lg:block fixed top-0 bottom-0 left-0">
              <div
                className={`relative w-[417px] h-full md:pt-[157px] md:px-12 bg-primary500`}
              >
                <img
                  className="absolute h-[119px] bottom-[66px] right-0"
                  src={SignBoard}
                  alt=""
                />
                <img
                  className="absolute w-[70px] bottom-[76px] right-0"
                  src={Subtract}
                  alt=""
                />
                <h1 className="text-a11y circularstdbold font-bold text-[32px] leading-[40.48px] mb-[38px] md:mr-4">
                  {customizationSettings?.pages?.businessRegistration?.title}
                </h1>
                <p className="text-a11y/90 avenirstdroman font-normal text-[18px] leading-[25px]">
                  {
                    customizationSettings?.pages?.businessRegistration
                      ?.description
                  }
                </p>
              </div>
            </div>
            <div className="lg:ml-[417px] lg:w-[calc(100%-417px)] min-h-screen h-full grow pt-[41px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px]">
              <div>
                <div className="flex justify-between gap-2">
                  <TextButton
                    title="Back"
                    className="flex gap-2 text-neutral500 font-medium items-center"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftIcon className="w-4 text-primary500" />
                    <span>Back</span>
                  </TextButton>
                  {isPadeDomain() ? (
                    <TextButton
                      title="Get help"
                      className="flex gap-2 items-center"
                      onClick={() =>
                        (window.location.href = "https://support.padehcm.com")
                      }
                    >
                      <span>Having troubles?</span>
                      <span className="text-primary500 underline">
                        Get help
                      </span>
                      <ArrowRightIcon className="w-4 text-primary500" />
                    </TextButton>
                  ) : null}
                </div>

                {isSubmitted ? (
                  <div className="flex flex-col items-center justify-center min-h-full mt-10 max-w-lg mx-auto">
                    <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
                    <h2 className="font-avenir font-bold text-neutral700 text-2xl mt-4 mb-4 text-center">
                      Email verification sent
                    </h2>
                    <p className="font-avenir text-base text-neutral400 text-center font-semibold">
                      A verification email has been sent to your inbox. Please
                      verify your email to continue.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2 className="mt-[50px] md:mt-[70px] circularstdbold font-bold text-[32px] leading-[40px] text-[neutral700 mb-[16px]">
                      Create your {customizationSettings?.brand?.platformName}{" "}
                      account
                    </h2>
                    <h3 className="max-w-[533px] avenirstdbook text-[16px] leading-[21px] text-neutral100 mb-[48px]">
                      Fill in the form below
                    </h3>

                    <div className="max-w-full lg:max-w-[541px] text-neutral400">
                      <div>
                        <FormBody
                          onSubmit={(values) => {
                            createAccount(values)
                          }}
                          submitting={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const products = [
  {
    name: "Payroll Management",
    value: "payrollManagement",
  },
  {
    name: "Performance Management",
    value: "performanceManagement",
  },
  {
    name: "Earned Wage Access",
    value: "earnedWageAccess",
  },
  {
    name: "HRIS",
    value: "hris",
  },
  {
    name: "Applicant Tracking System",
    value: "ats",
  },
]

const FormBody = ({
  onSubmit,
  submitting,
}: {
  onSubmit: (values: IForm1) => void
  submitting: boolean
}) => {
  const [form] = Form.useForm()
  // const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)

  const onFinish = (
    values: IForm1 & {
      tAndC: boolean
      employeeCount: string
      address: string
      city: string
      state: string
    }
  ) => {
    // if (!isCaptchaSuccessful) {
    //   return
    // }

    const { tAndC, employeeCount, address, city, state, ...rest } = values

    if (address || city || state) {
      return
    }

    onSubmit({
      ...rest,
      employeeCount: parseInt(employeeCount),
    })
  }

  const { isLoading: isCountriesLoading, data: countries } = useQuery<
    {
      description: string
      alpha2Code: string
    }[]
  >({
    queryKey: [`countries`],
    queryFn: async () => {
      const res = await axios.post(
        "https://padehcmmware.azurewebsites.net/commonutils/getcountries",
        {
          q: "",
          page: "",
        }
      )
      return res.data
    },
  })

  return (
    <div className="">
      <Form
        name="createAccount"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={
            <span className="font-circular text-neutral300 text-sm  ">
              Country
            </span>
          }
          name="country"
          rules={[{ required: true, message: "Please enter this field" }]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Please select"
            loading={isCountriesLoading}
            filterOption={(input, option) =>
              // @ts-ignore
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {countries?.map((item, idx) => (
              <Select.Option
                value={item.alpha2Code}
                key={idx}
              >
                {item.description}
              </Select.Option>
            ))}
            {["first", "second"].map((item) => (
              <Select.Option
                key={"empty" + item}
                className="pointer-events-none"
              >
                {" "}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="flex flex-col lg:flex-row lg:gap-4">
          <div className="w-full lg:w-1/2">
            <CustomInput
              label="First Name"
              name="firstName"
              required
            />
          </div>

          <div className="w-full lg:w-1/2">
            <CustomInput
              label="Last Name"
              name="lastName"
              required
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:gap-4">
          <div className="w-full lg:w-1/2">
            <Form.Item
              label={
                <span className="font-circular text-neutral300 text-sm  ">
                  Number of Employees
                </span>
              }
              name="employeeCount"
              rules={[
                {
                  required: true,
                  message: "Please enter this field",
                },
              ]}
            >
              <Input
                type="number"
                className="h-8 font-circular"
              />
            </Form.Item>
          </div>

          <div className="w-full lg:w-1/2">
            <Form.Item
              label={
                <span className="font-circular text-neutral300 text-sm  ">
                  Phone Number
                </span>
              }
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter this field",
                },
              ]}
            >
              <Input
                type="number"
                className="h-8 font-circular"
                maxLength={15}
              />
            </Form.Item>
          </div>
        </div>

        <CustomInput
          label="Company Name"
          name="companyName"
          required
        />

        <CustomInput
          label="Email"
          name="email"
          type="email"
          required
        />

        <Form.Item
          name={"products"}
          label={
            <span className="font-circular text-neutral300 text-sm">
              What Products are you interested in?
            </span>
          }
          rules={[
            {
              required: true,
              message: "Please select at least one product",
            },
          ]}
        >
          <Checkbox.Group>
            <Space direction="vertical">
              {products.map((item, idx) => {
                return (
                  <Checkbox
                    key={idx}
                    value={item.value}
                    className="text-[#5D6B82] font-circular"
                  >
                    {item?.name}
                  </Checkbox>
                )
              })}
            </Space>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label=""
          name="tAndC"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please agree to the terms and conditions",
            },
          ]}
        >
          <Checkbox>
            <span className="font-circular text-neutral300 text-sm">
              Signing up signifies that you have read and agree to the{" "}
              <a
                href={
                  isPadeDomain() === true
                    ? "https://www.paidhr.com/terms-and-conditions"
                    : ""
                }
                target="_blank"
                rel="noopener"
                className="text-primary500"
              >
                Terms of Service
              </a>{" "}
              and our{" "}
              <a
                href={
                  isPadeDomain() === true
                    ? "https://www.paidhr.com/privacy-policy"
                    : ""
                }
                target="_blank"
                rel="noopener"
                className="text-primary500"
              >
                Privacy Policy
              </a>
              .
            </span>
          </Checkbox>
        </Form.Item>

        {/* ============================================ */}
        {/* ===============DO NOT REMOVE================ */}
        {/* ====This is used as Honeypot for bots======== */}
        {/* ============================================ */}
        <div className="invisible h-0">
          <CustomInput
            label=""
            name="address"
          />
          <CustomInput
            label=""
            name="city"
          />
          <div className="absolute">
            <CustomInput
              label=""
              name="state"
            />
          </div>
        </div>
        {/* <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onChange={(value: string | null) => setIsCaptchaSuccess(!!value)}
        /> */}
        {/* ============================================ */}
        {/* ============================================ */}

        <div className="w-full flex gap-4 mt-6 lg:mt-10">
          <Button
            color="primary"
            type="submit"
            disabled={submitting}
            submitting={submitting}
            className="w-full max-w-[300px] mx-auto"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default OnboardingBusinessRegisteration
