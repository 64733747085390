import React from "react"
import { Navigate, Route } from "react-router-dom"

import { Spin } from "antd"
import EmployeeProfile from "pages/employee-dashboard/profile/EmployeeProfile"
import ViewReviewListing from "pages/employee-dashboard/reviews/ViewReviewListing"
import ViewReviewDetailed from "pages/employee-dashboard/reviews/ViewReviewDetailed"
import ViewReviewsEmployee from "pages/employee-dashboard/reviews/ViewReviewEmployee"
import DownloadPayslip from "pages/employee-dashboard/payslip/downloadPayslips"
import NewResponsiveEmployeeLayout from "components/new-stuff/layout/employee/EmployeeLayout"
import { ViewViolationPage } from "pages/employee-dashboard/disciplinary/ViewViolationPage"
import TwoFaSetupPage from "pages/2fa/TwoFaSetUpPage"
import EarnedWageAccess from "pages/employee-dashboard/wallet/ewa/EarnedWageAccess"
import Transactions from "pages/employee-dashboard/wallet/transactions/Transanctions"
import Withdrawal from "pages/employee-dashboard/wallet/ewa/Withdrawal"
import Wallet from "pages/employee-dashboard/wallet/wallet/Wallet"
import KYC from "pages/employee-dashboard/wallet/wallet/kyc/KYC"
import CreatePin from "pages/employee-dashboard/wallet/wallet/pin/CreatePin"

import Beneficiaries from "pages/employee-dashboard/wallet/wallet/beneficiaries/Beneficiaries"
import WalletTransactions from "pages/employee-dashboard/wallet/wallet/transactions/Transactions"
import SendMoneyBank from "pages/employee-dashboard/wallet/wallet/SendMoneyBank"
import SendMoneyWallet from "pages/employee-dashboard/wallet/wallet/SendMoneyWallet"
import SuspendedExitedEmployeeLayout from "components/new-stuff/layout/suspended-exited-employee/SuspendedExitedEmployeeLayout"
import ViewTransaction from "pages/employee-dashboard/wallet/wallet/transactions/component/ViewTransaction"

const Dashboard = React.lazy(
  () => import("pages/employee-dashboard/Dashboard/Dashboard")
)

const EmployeeOnboarding = React.lazy(
  () => import("pages/employee-dashboard/onboarding")
)

const JoyAndPay = React.lazy(
  () => import("pages/employee-dashboard/job-and-pay/JobAndPay")
)
const Survey = React.lazy(
  () => import("pages/employee-dashboard/survey/Survey")
)
const Personal = React.lazy(
  () => import("pages/employee-dashboard/personal/Personal")
)

// const RequestLeaves = React.lazy(
//   () => import("pages/employee-dashboard/requests/LeaveRequests")
// )

const EmployeeAllRequest = React.lazy(
  () => import("pages/employee-dashboard/requests/EmployeeAllRequest")
)

const CreateExitRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateExitRequest")
)

const CreateLeaveRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateLeaveRequest")
)

const CreateLoanRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateLoanRequest")
)

const CreateSalaryAdvance = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateSalaryAdvanceRequest"
    )
)

const CreateRedeploymentRequest = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateRedeploymentRequest"
    )
)

const CreateDocumentRequest = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateDocumentRequest"
    )
)

const LeaveBalances = React.lazy(
  () => import("pages/employee-dashboard/requests/components/LeaveBalances")
)

const CreateViolationRequest = React.lazy(
  () => import("pages/employee-dashboard/disciplinary/CreateViolationRequest")
)

const Document = React.lazy(
  () => import("pages/employee-dashboard/documents/Documents")
)

const Benefits = React.lazy(
  () => import("pages/employee-dashboard/benefits/Benefit")
)
const Claims = React.lazy(
  () => import("pages/employee-dashboard/claims/Claims")
)
const CreateNewClaim = React.lazy(
  () => import("pages/employee-dashboard/claims/CreateNewClaim")
)
const Payslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/Payslip")
)

const ViewPayslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/viewPayslip")
)
const ViewAllPayslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/viewAllPayslips")
)

const BillPayments = React.lazy(
  () => import("pages/employee-dashboard/bill-payments/BillPayments")
)

const Assets = React.lazy(
  () => import("pages/employee-dashboard/assets/Assets")
)

const Tasks = React.lazy(() => import("pages/employee-dashboard/tasks/Task"))
const ViewTask = React.lazy(
  () => import("pages/employee-dashboard/tasks/EditRoutineTaskForEmployee")
)

// const Performance = React.lazy(
//   () => import("pages/employee-dashboard/performance/Performance")
// )

const Disciplinary = React.lazy(
  () => import("pages/employee-dashboard/disciplinary/Disciplinary")
)

const Notes = React.lazy(() => import("pages/employee-dashboard/notes/Notes"))

const PerformanceObjectives = React.lazy(
  () => import("pages/employee-dashboard/performance/Objectives")
)
const PerformanceManagement = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/performance/performance-management/PerformanceManagement"
    )
)
const PerformanceManagementHistory = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/performance/performance-management/PerformanceManagementHistory"
    )
)
const NewGoal = React.lazy(
  () => import("pages/employee-dashboard/performance/goals/NewGoal")
)
const Goals = React.lazy(
  () => import("pages/employee-dashboard/performance/goals/Goals")
)
const Objectives = React.lazy(
  () => import("pages/employee-dashboard/performance/objectives/Objectives")
)

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0  left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}

const EmployeeDashboard = () => {
  return (
    <>
      {/* suspended or exited employees */}
      <Route
        path="/employee/susp-exit"
        element={<SuspendedExitedEmployeeLayout />}
      >
        <Route
          index
          element={<Navigate to={"payslips"} />}
        />
        <Route
          path="payslips"
          element={<Payslip />}
        />
        <Route
          path="payslip/view-payslip"
          element={<ViewPayslip />}
        />
        <Route
          path="payslip/download-payslip"
          element={<DownloadPayslip />}
        />
        <Route
          path="payslip/all-payslips"
          element={<ViewAllPayslip />}
        />
        {/* WALLET */}
        <Route
          path="wallet/overview"
          element={<Wallet />}
        />
        <Route
          path="wallet/kyc"
          element={<KYC />}
        />
        <Route
          path="wallet/beneficiaries"
          element={<Beneficiaries />}
        />
        <Route
          path="wallet/transactions"
          element={<WalletTransactions />}
        />
        <Route
          path="wallet/transactions/view"
          element={<ViewTransaction />}
        />
        <Route
          path="wallet/create-pin"
          element={<CreatePin />}
        />
        <Route
          path="wallet/send-money-bank"
          element={<SendMoneyBank />}
        />
        <Route
          path="wallet/send-money-wallet"
          element={<SendMoneyWallet />}
        />

        <Route
          path="wallet/earned-wage-access/overview"
          element={<EarnedWageAccess />}
        />
        <Route
          path="wallet/earned-wage-access/transactions"
          element={<Transactions />}
        />
        <Route
          path="wallet/earned-wage-access/withdrawal"
          element={<Withdrawal />}
        />
      </Route>
      <Route
        path="/employee/dashboard"
        element={<NewResponsiveEmployeeLayout />}
      >
        <Route
          index
          // path="/employee/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="twofa-setup"
          element={<TwoFaSetupPage />}
        />
        <Route
          path="people"
          Component={React.lazy(
            () => import("../pages/employee-dashboard/people/EmployeesList")
          )}
        />
        <Route
          path="notifications"
          Component={React.lazy(() => import("../pages/notifications/"))}
        />
        {/* Profile */}
        <Route
          path="profile"
          element={<EmployeeProfile />}
        />
        <Route
          path="welcome"
          element={<EmployeeOnboarding />}
        />
        <Route
          path="job-and-pay"
          element={<JoyAndPay />}
        />
        <Route
          path="personal"
          element={<Personal />}
        />
        <Route
          path="requests/all"
          element={<EmployeeAllRequest />}
        />
        <Route
          path="requests/leave-balances"
          element={<LeaveBalances />}
        />
        <Route
          path="requests/all/create-exit"
          element={<CreateExitRequest />}
        />
        <Route
          path="requests/all/create-leave"
          element={<CreateLeaveRequest />}
        />
        <Route
          path="requests/all/create-loan"
          element={<CreateLoanRequest />}
        />
        <Route
          path="requests/all/create-salaryadvance"
          element={<CreateSalaryAdvance />}
        />
        <Route
          path="requests/all/create-redeployment"
          element={<CreateRedeploymentRequest />}
        />
        <Route
          path="requests/all/create-document"
          element={<CreateDocumentRequest />}
        />
        <Route
          path="disciplinary/create-violation"
          element={<CreateViolationRequest />}
        />
        <Route
          path="document"
          element={<Document />}
        />
        <Route
          path="survey"
          element={<Survey />}
        />
        <Route
          path="benefits"
          element={<Benefits />}
        />
        <Route
          path="claims"
          element={<Claims />}
        />
        <Route
          path="claims/new"
          element={<CreateNewClaim />}
        />
        <Route
          path="payslip"
          element={<Payslip />}
        />
        <Route
          path="payslip/view-payslip"
          element={<ViewPayslip />}
        />
        <Route
          path="payslip/download-payslip"
          element={<DownloadPayslip />}
        />
        <Route
          path="payslip/all-payslips"
          element={<ViewAllPayslip />}
        />
        <Route
          path="wallet/overview"
          element={<Wallet />}
        />
        <Route
          path="wallet/kyc"
          element={<KYC />}
        />
        <Route
          path="wallet/beneficiaries"
          element={<Beneficiaries />}
        />
        <Route
          path="wallet/transactions"
          element={<WalletTransactions />}
        />
        <Route
          path="wallet/transactions/view"
          element={<ViewTransaction />}
        />
        <Route
          path="wallet/create-pin"
          element={<CreatePin />}
        />
        <Route
          path="wallet/send-money-bank"
          element={<SendMoneyBank />}
        />
        <Route
          path="wallet/send-money-wallet"
          element={<SendMoneyWallet />}
        />

        <Route
          path="wallet/earned-wage-access/overview"
          element={<EarnedWageAccess />}
        />
        <Route
          path="wallet/earned-wage-access/transactions"
          element={<Transactions />}
        />
        <Route
          path="wallet/earned-wage-access/withdrawal"
          element={<Withdrawal />}
        />
        <Route
          path="bill-payments"
          element={<BillPayments />}
        />
        <Route
          path="assets"
          element={<Assets />}
        />
        <Route
          path="tasks"
          element={<Tasks />}
        />
        <Route
          path="tasks/:taskId"
          element={<ViewTask />}
        />
        <Route
          path="performance"
          element={<PerformanceObjectives />}
        />
        {/* <Route
        path="performance-management"
        element={<PerformanceManagement />}
      />
      <Route
        path="performance-management/history"
        element={<PerformanceManagementHistory />}
      /> */}
        <Route
          path="reviews"
          element={<ViewReviewListing />}
        />
        <Route
          path="reviews/view"
          element={<ViewReviewDetailed />}
        />
        <Route
          path="reviews/view-employee"
          element={<ViewReviewsEmployee />}
        />
        <Route
          path="disciplinary"
          element={<Disciplinary />}
        />
        <Route
          path="disciplinary/violations/:violationId"
          element={<ViewViolationPage />}
        />
        <Route
          path="notes"
          element={<Notes />}
        />
      </Route>
    </>
  )
}

export default EmployeeDashboard
